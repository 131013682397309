<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("family_information") }}
                    </div>
                    <Divider></Divider>
                    <div class="text-purple-500">
                      <div class="font-bold text-yellow-500 mb-2">
                        {{ $t("instructions") }}
                      </div>
                      <ul>
                        <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                        <li>
                          {{
                            $t(
                              "all_telephone_numbers_must_start_with_the_country_code"
                            )
                          }}
                        </li>
                        <li>
                          Ex: <span class="text-green-500">+233 209013836</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading">
                <div class="grid">
                  <div class="col-12">
                    <form
                      ref="observer"
                      tag="form"
                      @submit.prevent="startRecordUpdate()"
                    >
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("fathers_information") }}
                        </div>

                        <div class="grid">
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("fathers_firstname") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText
                                ref="ctrlfathers_firstname"
                                v-model.trim="formData.fathers_firstname"
                                :label="$t('fathers_firstname')"
                                type="text"
                                :placeholder="$t('fathers_firstname')"
                                
                                class="w-full"
                                :class="getErrorClass('fathers_firstname')"
                              >
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("fathers_lastname") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText
                                ref="ctrlfathers_lastname"
                                v-model.trim="formData.fathers_lastname"
                                :label="$t('fathers_lastname')"
                                type="text"
                                :placeholder="$t('fathers_lastname')"
                                
                                class="w-full"
                                :class="getErrorClass('fathers_lastname')"
                              >
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("fathers_living_status") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown
                                class="w-full"
                                :class="getErrorClass('fathers_living_status')"
                                optionLabel="label"
                                optionValue="value"
                                ref="ctrlfathers_living_status"
                                v-model="formData.fathers_living_status"
                                :options="$menus.living_statusItems"
                                :label="$t('fathers_living_status')"
                                :placeholder="$t('select')"
                                @change="validateLivingStatus('father')"
                              >
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div
                            v-if="formData.fathers_living_status == alive"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("fathers_telephone") }} *
                            </div>
                            <div class="grid">
                              <div class="col-12 md:col-5">
                                <div class="">
                                  <!-- <div class="field mb-0"> -->
                                  <Dropdown
                                    class="w-full"
                                    :class="
                                      getErrorClass(
                                        'fathers_telephone_dial_code'
                                      )
                                    "
                                    optionLabel="dial_code"
                                    optionValue="value"
                                    ref="ctrlfathers_telephone_dial_code"
                                    :filter="true"
                                    :filterFields="['name', 'dial_code']"
                                    v-model="
                                      formData.fathers_telephone_dial_code
                                    "
                                    :options="$menus.dial_codes_list"
                                    :label="$t('dial_code')"
                                    :placeholder="$t('dial_code')"
                                  >
                                    <template #option="slotProps">
                                       <div class="flex align-items-center">
                                          <div>
                                            <div>
                                              {{ slotProps.option.name }}
                                            </div>
                                            <div
                                              v-if="slotProps.option.name"
                                              class="text-sm text-500"
                                            >
                                              {{ slotProps.option.code }} 
                                              {{ slotProps.option.dial_code }}
                                            </div>
                                          </div>
                                        </div>
                                    </template>
                                  </Dropdown>
                                  <!-- </div> -->
                                </div>
                              </div>
                              <div class="col-12 md:col-7">
                                <div class="">
                                  <!-- <div class="field mb-0"> -->
                                  <InputText
                                    ref="ctrlfathers_telephone"
                                    v-model.trim="formData.fathers_telephone"
                                    :label="$t('fathers_telephone')"
                                    type="text"
                                    :placeholder="$t('fathers_telephone')"
                                    pattern="^[0-9]+$"
                                    class="w-full"
                                    maxlength="10"
                                     minlength="8"
                                    :class="getErrorClass('fathers_telephone')"
                                    v-tooltip.top.focus="'Eg: 0209013836'"
                                  >
                                  </InputText>
                                  <!-- </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="formData.fathers_living_status == alive"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("fathers_occupation") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown
                                class="w-full"
                                :class="getErrorClass('fathers_occupation')"
                                optionLabel="label"
                                optionValue="value"
                                :filter="true"
                                ref="ctrlfathers_occupation"
                                v-model="formData.fathers_occupation"
                                :options="$menus.occupations_list"
                                :label="$t('fathers_occupation')"
                                :placeholder="$t('select')"
                                @change="validateOccupation('father')"
                              >
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div
                            v-if="formData.fathers_occupation == other"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("specify_other") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText
                                ref="ctrlfathers_occupation_other"
                                v-model.trim="formData.fathers_occupation_other"
                                :label="$t('specify_other')"
                                type="text"
                                :placeholder="$t('specify_other')"
                                
                                class="w-full"
                                :class="
                                  getErrorClass('fathers_occupation_other')
                                "
                              >
                              </InputText>
                              <small class="text-xs text-primary mx-1"
                                >{{ $t("specify_other") }}
                                {{ $t("fathers_occupation") }}</small
                              >
                              <!-- </div> -->
                            </div>
                          </div>
                          <div
                            v-if="formData.fathers_living_status == alive"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("fathers_email") }}
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText
                                ref="ctrlfathers_email"
                                v-model.trim="formData.fathers_email"
                                :label="$t('fathers_email')"
                                type="email"
                                :placeholder="$t('fathers_email')"
                                class="w-full"
                                maxlength="40"
                                :class="getErrorClass('fathers_email')"
                              >
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("mothers_information") }}
                        </div>

                        <div class="grid">
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("mothers_firstname") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText
                                ref="ctrlmothers_firstname"
                                v-model.trim="formData.mothers_firstname"
                                :label="$t('mothers_firstname')"
                                type="text"
                                :placeholder="$t('mothers_firstname')"
                                
                                class="w-full"
                                :class="getErrorClass('mothers_firstname')"
                              >
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("mothers_lastname") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText
                                ref="ctrlmothers_lastname"
                                v-model.trim="formData.mothers_lastname"
                                :label="$t('mothers_lastname')"
                                type="text"
                                :placeholder="$t('mothers_lastname')"
                                
                                class="w-full"
                                :class="getErrorClass('mothers_lastname')"
                              >
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("mothers_living_status") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown
                                class="w-full"
                                :class="getErrorClass('mothers_living_status')"
                                optionLabel="label"
                                optionValue="value"
                                ref="ctrlmothers_living_status"
                                v-model="formData.mothers_living_status"
                                :options="$menus.living_statusItems"
                                :label="$t('mothers_living_status')"
                                :placeholder="$t('select')"
                                @change="validateLivingStatus('mother')"
                              >
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div
                            v-if="formData.mothers_living_status == alive"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("mothers_telephone") }} *
                            </div>
                            <div class="grid">
                              <div class="col-12 md:col-5">
                                <div class="">
                                  <!-- <div class="field mb-0"> -->
                                  <Dropdown
                                    class="w-full"
                                    :class="
                                      getErrorClass(
                                        'mothers_telephone_dial_code'
                                      )
                                    "
                                    optionLabel="dial_code"
                                    optionValue="value"
                                    ref="ctrlmothers_telephone_dial_code"
                                    :filter="true"
                                    :filterFields="['name', 'dial_code']"
                                    v-model="
                                      formData.mothers_telephone_dial_code
                                    "
                                    :options="$menus.dial_codes_list"
                                    :label="$t('dial_code')"
                                    :placeholder="$t('dial_code')"
                                  >
                                    <template #option="slotProps">
                                      <div class="flex align-items-center">
                                          <div>
                                            <div>
                                              {{ slotProps.option.name }}
                                            </div>
                                            <div
                                              v-if="slotProps.option.name"
                                              class="text-sm text-500"
                                            >
                                              {{ slotProps.option.code }} 
                                              {{ slotProps.option.dial_code }}
                                            </div>
                                          </div>
                                        </div>
                                    </template>
                                  </Dropdown>
                                  <!-- </div> -->
                                </div>
                              </div>
                              <div class="col-12 md:col-7">
                                <div class="">
                                  <!-- <div class="field mb-0"> -->
                                  <InputText
                                    ref="ctrlmothers_telephone"
                                    v-model.trim="formData.mothers_telephone"
                                    :label="$t('mothers_telephone')"
                                    type="text"
                                    :placeholder="$t('mothers_telephone')"
                                    pattern="^[0-9]+$"
                                    class="w-full"
                                    maxlength="10"
                                    minlength="8"
                                    :class="getErrorClass('mothers_telephone')"
                                    v-tooltip.top.focus="'Eg: 0209013836'"
                                  >
                                  </InputText>
                                  <!-- </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="formData.mothers_living_status == alive"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("mothers_occupation") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <Dropdown
                                class="w-full"
                                :class="getErrorClass('mothers_occupation')"
                                optionLabel="label"
                                optionValue="value"
                                :filter="true"
                                ref="ctrlmothers_occupation"
                                v-model="formData.mothers_occupation"
                                :options="$menus.occupations_list"
                                :label="$t('mothers_occupation')"
                                :placeholder="$t('select')"
                                @change="validateOccupation('mother')"
                              >
                              </Dropdown>
                              <!-- </div> -->
                            </div>
                          </div>
                          <div
                            v-if="formData.mothers_occupation == other"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("specify_other") }} *
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText
                                ref="ctrlmothers_occupation_other"
                                v-model.trim="formData.mothers_occupation_other"
                                :label="$t('specify_other')"
                                type="text"
                                :placeholder="$t('specify_other')"
                                
                                class="w-full"
                                :class="
                                  getErrorClass('mothers_occupation_other')
                                "
                              >
                              </InputText>
                              <small class="text-xs text-primary mx-1"
                                >{{ $t("specify_other") }}
                                {{ $t("mothers_occupation") }}</small
                              >
                              <!-- </div> -->
                            </div>
                          </div>
                          <div
                            v-if="formData.mothers_living_status == alive"
                            class="col-12 md:col-6"
                          >
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("mothers_email") }}
                            </div>
                            <div class="">
                              <!-- <div class="field mb-0"> -->
                              <InputText
                                ref="ctrlmothers_email"
                                v-model.trim="formData.mothers_email"
                                :label="$t('mothers_email')"
                                type="email"
                                :placeholder="$t('mothers_email')"
                                class="w-full"
                                maxlength="40"
                                :class="getErrorClass('mothers_email')"
                              >
                              </InputText>
                              <!-- </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--[form-content-end]-->
                      <div v-if="showSubmitButton" class="text-center my-3">
                        <Button
                          type="submit"
                          :label="$t('save_and_continue')"
                          icon="pi pi-send"
                          :loading="saving"
                        />
                      </div>
                    </form>
                    <slot :submit="submit" :saving="saving"></slot>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "familydataApplicantsPage",
  components: {},
  mixins: [PageMixin, ApplicantEditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsfamilydata",
    },
    pagePath: {
      type: String,
      default: "applicants/familydata",
    },
    apiPath: {
      type: String,
      default: "applicants/familydata",
    },
    pageIndex: { type: Number, default: 2 },
  },
  data() {
    return {
      formData: {
        fathers_firstname: "",
        fathers_lastname: "",
        fathers_telephone: "",
        fathers_occupation: "",
        fathers_occupation_other: "",
        mothers_firstname: "",
        mothers_lastname: "",
        mothers_telephone: "",
        mothers_occupation: "",
        mothers_occupation_other: "",
        fathers_email: "",
        mothers_email: "",
        fathers_living_status: "",
        mothers_living_status: "",
        fathers_telephone_dial_code: "233",
        mothers_telephone_dial_code: "233",
      },
      submitted: false,
      alive: "Alive",
      other: "Other",
    };
  },
  computed: {
    ...mapGetters("applicants", ["familyDataRecord"]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      fathers_telephone: {
        required: requiredIf(() => {
          const esit = this.formData.fathers_living_status == this.alive;
          return esit;
        }),
      },
      fathers_telephone_dial_code: {
        required: requiredIf(() => {
          const esit = this.formData.fathers_living_status == this.alive;
          return esit;
        }),
      },
      fathers_occupation: {
        required: requiredIf(() => {
          const esit = this.formData.fathers_living_status == this.alive;
          return esit;
        }),
      },
      fathers_occupation_other: {
        required: requiredIf(() => {
          const esit = this.formData.fathers_occupation == this.other;
          return esit;
        }),
      },
      mothers_telephone: {
        required: requiredIf(() => {
          const esit = this.formData.mothers_living_status == this.alive;
          return esit;
        }),
      },
      mothers_telephone_dial_code: {
        required: requiredIf(() => {
          const esit = this.formData.mothers_living_status == this.alive;
          return esit;
        }),
      },
      mothers_occupation: {
        required: requiredIf(() => {
          const esit = this.formData.mothers_living_status == this.alive;
          return esit;
        }),
      },
      mothers_occupation_other: {
        required: requiredIf(() => {
          const esit = this.formData.mothers_occupation == this.other;
          return esit;
        }),
      },
      mothers_firstname: { required },
      mothers_lastname: { required },
      fathers_firstname: { required },
      fathers_lastname: { required },
      fathers_email: { email },
      mothers_email: { email },
      fathers_living_status: { required },
      mothers_living_status: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", ["updateFamilyData", "fetchFamilyData"]),
    load: function () {
      var url = this.apiUrl;
      this.loading = true;
      this.ready = false; // hide other components until main page is ready
      this.fetchFamilyData(url).then(
        (response) => {
          this.loading = false;
          this.ready = true;
          // this.familyDataRecord = response.data;
          this.formData = response.data;
          this.updateFormFields();
        },
        (response) => {
          this.resetForm();
          this.loading = false;
          this.showPageRequestError(response);
        }
      );
    },
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateFamilyData(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(
            this.$t("information_was_updated"),
            this.$t("form_saved_successfully")
          );
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          this.$emit("next", this.pageIndex);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    validateLivingStatus(parent_type) {
      if (parent_type == "mother") {
        if (this.formData.mothers_living_status == "Deceased") {
          this.formData.mothers_occupation = "";
          this.formData.mothers_telephone = "";
          this.formData.mothers_telephone_dial_code = "";
          this.formData.mothers_occupation_other = "";
          this.formData.mothers_email = "";
        }
      } else {
        if (this.formData.fathers_living_status == "Deceased") {
          this.formData.fathers_telephone = "";
          this.formData.fathers_telephone_dial_code = "";
          this.formData.fathers_occupation = "";
          this.formData.fathers_occupation_other = "";
          this.formData.fathers_email = "";
        }
      }
    },
    validateOccupation(parent_type) {
      if (parent_type == "mother") {
        if (this.formData.mothers_occupation != this.other) {
          this.formData.mothers_occupation_other = "";
        }
      } else {
        if (this.formData.fathers_occupation != this.other) {
          this.formData.fathers_occupation_other = "";
        }
      }
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        fathers_firstname: "",
        fathers_lastname: "",
        fathers_telephone: "",
        fathers_occupation: "",
        fathers_occupation_other: "",
        mothers_firstname: "",
        mothers_lastname: "",
        mothers_telephone: "",
        mothers_occupation: "",
        mothers_occupation_other: "",
        fathers_email: "",
        mothers_email: "",
        fathers_living_status: "",
        mothers_living_status: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {
    this.load();
  },
  async created() {},
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}
</style>
